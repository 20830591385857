import {Component, Vue} from 'vue-property-decorator';
import {Evaluation} from "../../shared/model/Evaluation";
import {EvaluationType} from "../../shared/model/EvaluationType";
import {ProviderType} from "../../shared/model/ProviderType";
import {ActivityType} from "../../shared/model/ActivityType";
import {County} from "../../shared/model/County";
import {Status} from "../../shared/model/Status";
import {FiscalYear} from "../../shared/model/FiscalYear";
import {Department} from "../../shared/model/Department";
import {rules} from "../../shared/model/Rules";
import {evalApi, globalConfig, surveyApi} from "@/plugins/axios";


@Component({
    components: {},
})
export default class EvaluationForm extends Vue {
    title = "Evaluation Form"
    maskText = "";
    valid = true;
    evaluation: Evaluation = new Evaluation();
    menu1 = false;
    evaluationTypes = EvaluationType.values;
    providerTypes = ProviderType.values;
    activityTypes = ActivityType.values;
    counties = County.values;
    surveyTypes: Array<String> = [];
    statuses = Status.values;
    fiscalYears = new FiscalYear().values
    endDateString: string = "";
    dueDateString: string = "";
    departments = Department.values.sort();
    requiredRule = rules;

    public created() {
        this.loadSurveyTypes()
    }

    get computedEndDate(): String {
        return this.formatDate(this.endDateString);
    }

    get computedDueDate(): String {
        return this.formatDate(this.dueDateString);
    }

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    submitNewEvaluation(): void {
        evalApi.post("/evaluationsImport/create", {
            providerNumber: this.evaluation.providerNumber,
            providerType: this.evaluation.providerType,
            providerName: this.evaluation.providerName,
            evaluationNumber:this.evaluation.evaluationNumber,
            surveyType:this.evaluation.surveyType,
            department:this.evaluation.department,
            endDate:this.evaluation.endDate,
            dueDate:this.evaluation.dueDate,
            jiraIssueKey:this.evaluation.jiraIssueKey,
            evaluationType:this.evaluation.evaluationType,
            status:this.evaluation.status = "ACTIVE",
            jobName:this.evaluation.jobName = "EvaluationsImport"

        }, globalConfig).then(res => {
            //window.location.reload();
            alert(res.data.message + " " + res.data.detail)
        }).catch(error => {
            console.log(`An error occurred submitting the evaluation number: >>>> ${error}`)
        })
    }

    public loadSurveyTypes(): void {
        evalApi.post('/surveyType/getByFlag', {flag: this.evaluation.evaluationType}, globalConfig)
            .then((res: any) => {
                this.surveyTypes = res.data;
            })
    }
}