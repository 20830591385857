export class Evaluation {
    id?: any;
    providerNumber?: any;
    evaluationNumber?: any;
    surveyType?: any;
    department?: any;
    district?: any;
    initialIndicator?: any;
    medicare?: any;
    insertUid?: any;
    insertDate?: any;
    aspenEvent?: any;
    tierPriority?: any;
    chowIndicator?: any;
    jiraIssueKey?: any;
    evaluationType?: any;
    type?: any;
    providerName?: any;
    providerCounty?: any;
    providerType?: any;
    fiscalYear?: any;
    modifyUid?: any;
    modifyDate?: any;
    jobName?: any;
    endDate?: Date;
    status?: any;
    dueDate?: Date;
}